import React, { useState, useEffect } from 'react';
import {
    TextInput,
    Button,
    Text,
    Container,
    Card,
    Stack,
    Group,
    Image,
    UnstyledButton
} from '@mantine/core';
import { IconPencil, IconSearch, IconUpload } from '@tabler/icons-react';
import './CapturePage.css';

const UploadPage = () => {
    // State Management
    const [ocrResult, setOcrResult] = useState('');
    const [croppedImageUrl, setCroppedImageUrl] = useState('');
    const [formValues, setFormValues] = useState({
        EILANDENCARIBISCHNEDERLAND: '',
        WOONADRES: '',
        District: '',
        vak: '',
        Volgorde: ''
    });
    const [isEditable, setIsEditable] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [userData, setUserData] = useState(null);
    const [ocrCompleted, setOcrCompleted] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    // Configuration
    const OCR_BASE_URI = "https://model.fxdc-post.com";
    const BASE_URI = "https://api.fxdc-post.com";

    // Effect to load user data
    useEffect(() => {
        const storedUserData = localStorage.getItem('user');
        if (storedUserData) {
            try {
                setUserData(JSON.parse(storedUserData));
            } catch (error) {
                console.error('Error parsing user data:', error);
            }
        }
    }, []);

    // File Upload and OCR Processing
    const handleFileUpload = async (file) => {
        if (!file) return;

        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await fetch(`${OCR_BASE_URI}/predict/`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('OCR processing failed');
            }

            const result = await response.json();
            setOcrResult(result.ocr_result);
            setCroppedImageUrl(result.cropped_image_url);
            setOcrCompleted(true);
            setIsSubmitted(false);
            setSearchResults([]);

            // Attempt to search in database
            await searchInDatabase(result.ocr_result);

        } catch (error) {
            console.error('OCR Error:', error);
            alert('Failed to process image. Please try again.');
        }
    };

    // Database Search Function
    const searchInDatabase = async (ocrText) => {
        // Split and clean OCR text
        const lines = ocrText.split('\n')
            .filter(line => line.trim() !== '')
            .map(line => line.trim());

        for (const line of lines) {
            try {
                const response = await fetch(`${BASE_URI}/admin/searchletter`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ address: line }),
                });

                if (!response.ok) continue;

                const data = await response.json();
                if (data && Object.keys(data).length > 0) {
                    setSearchResults([data]);
                    return;
                }
            } catch (error) {
                console.error(`Search error for line "${line}":`, error);
            }
        }

        // If no results found
        alert('No matching address found');
        setSearchResults([]);
    };

    // Form Handling
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async () => {
        // Validate form fields
        const requiredFields = ['street', 'houseNumber', 'route', 'area', 'code'];
        const missingFields = requiredFields.filter(field => !formValues[field]);

        if (missingFields.length > 0) {
            alert(`Please fill in all fields: ${missingFields.join(', ')}`);
            return;
        }

        try {
            const response = await fetch(`${BASE_URI}/admin/createletter`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    EILANDENCARIBISCHNEDERLAND: formValues.EILANDENCARIBISCHNEDERLAND,
                    WOONADRES: formValues.WOONADRES,
                    District: formValues.District,
                    vak: formValues.vak,
                    Volgorde: formValues.Volgorde,
                    uploadedBy: userData?.email || "anonymous"
                }),
            });

            if (!response.ok) throw new Error('Submission failed');

            setIsSubmitted(true);
            setFormValues({
                EILANDENCARIBISCHNEDERLAND: '',
                WOONADRES: '',
                District: '',
                vak: '',
                Volgorde: ''
            });
        } catch (error) {
            console.error('Submission Error:', error);
            alert('Failed to submit data. Please try again.');
        }
    };

    return (
        <Container
            size="xs"
            className="capture-container"
            style={{
                height: '100vh',
                padding: 0,
                margin: 0,
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '100%'
            }}
        >
            <Card
                className="capture-card"
                shadow="sm"
                padding="lg"
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden'
                }}
            >
                <Stack
                    gap="md"
                    style={{
                        flex: 1,
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        paddingRight: '10px' // Add slight padding for scrollbar
                    }}
                >
                    <Text className="capture-title" ta="center">Upload An Image</Text>

                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleFileUpload(e.target.files[0])}
                        className="form-input"
                    />

                    {croppedImageUrl && (
                        <Image
                            src={croppedImageUrl}
                            alt="Cropped"
                            className="captured-image"
                        />
                    )}

                    {ocrCompleted !== false && (
                        <div style={{ paddingBottom: '2rem', paddingTop: '2rem' }}>
                            <Group justify="space-between" align="center" mb="xs">
                                <Text fw={500}>OCR Result:</Text>
                                <div style={{ paddingBottom: '2rem', width: '100%' }}>
                                    {isEditable ? (
                                        <TextInput
                                            value={ocrResult}
                                            onChange={(e) => setOcrResult(e.target.value)}
                                            variant="filled"
                                            placeholder="OCR Result"
                                            style={{ width: '100%' }}
                                        />
                                    ) : (
                                        <Text>{ocrResult || 'No text detected'}</Text>
                                    )}
                                </div>
                                <Group gap="xs">
                                    <UnstyledButton
                                        className={`ocr-action-button ${isEditable ? 'edit-button' : ''}`}
                                        onClick={() => setIsEditable(!isEditable)}
                                    >
                                        <Group gap={6} align="center">
                                            <IconPencil size={16} stroke={1.5} />
                                            <Text size="sm">{isEditable ? 'Save' : 'Edit'}</Text>
                                        </Group>
                                    </UnstyledButton>

                                    <UnstyledButton
                                        className="ocr-action-button retry-search-button"
                                        onClick={() => searchInDatabase(ocrResult)}
                                    >
                                        <Group gap={6} align="center">
                                            <IconSearch size={16} stroke={1.5} />
                                            <Text size="sm">Retry Search</Text>
                                        </Group>
                                    </UnstyledButton>
                                </Group>
                            </Group>
                        </div>
                    )}

                    {ocrCompleted && searchResults.length === 0 && (
                        <Stack>
                            <TextInput
                                label="EILANDENCARIBISCHNEDERLAND"
                                name="street"
                                value={formValues.EILANDENCARIBISCHNEDERLAND}
                                onChange={handleInputChange}
                                variant="filled"
                                required
                            />
                            <TextInput
                                label="WOONADRES"
                                name="houseNumber"
                                value={formValues.WOONADRES}
                                onChange={handleInputChange}
                                variant="filled"
                                required
                            />
                            <TextInput
                                label="District"
                                name="route"
                                value={formValues.District}
                                onChange={handleInputChange}
                                variant="filled"
                                required
                            />
                            <TextInput
                                label="Vak"
                                name="area"
                                value={formValues.vak}
                                onChange={handleInputChange}
                                variant="filled"
                                required
                            />
                            <TextInput
                                label="Volgorde"
                                name="code"
                                value={formValues.Volgorde}
                                onChange={handleInputChange}
                                variant="filled"
                                required
                            />
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem', marginBottom: '4rem' }}>
                                <button
                                    className="submit-button"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        </Stack>
                    )}

                    {searchResults.length > 0 && (
                        <div style={{ marginBottom: '5rem' }}>
                            <Card withBorder>
                                <Text fw={500} mb="xs">Search Result:</Text>
                                <Text>
                                    Sequence: {searchResults[0].District}.
                                    {searchResults[0].vak}.
                                    {searchResults[0].Volgorde}
                                </Text>
                            </Card>
                        </div>
                    )}

                    {isSubmitted && (
                        <Text c="green" ta="center" fw={500}>
                            Submitted Successfully!
                        </Text>
                    )}
                </Stack>
            </Card>
        </Container>
    );



};

export default UploadPage;