import Webcam from "react-webcam";
import React, { useState, useRef, useCallback, useEffect } from "react";
import { isMobile } from 'react-device-detect';

const CustomWebcam = ({ onImageCapture }) => {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [useBackCamera, setUseBackCamera] = useState(isMobile);
  const [deviceId, setDeviceId] = useState(null);
  const [cameras, setCameras] = useState([]);

  const videoConstraints = {
    width: isMobile ? { ideal: 1280 } : { ideal: 1280 },
    height: isMobile ? { ideal: 1280 } : { ideal: 1280 },
    facingMode: useBackCamera ? "environment" : "user",
    deviceId: deviceId ? { exact: deviceId } : undefined,
  };

  useEffect(() => {
    async function getCameras() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        setCameras(videoDevices);
        
        if (isMobile && useBackCamera) {
          const backCamera = videoDevices.find(device => 
            device.label.toLowerCase().includes('back') ||
            device.label.toLowerCase().includes('environment')
          );
          if (backCamera) {
            setDeviceId(backCamera.deviceId);
          }
        }
      } catch (error) {
        console.error('Error getting cameras:', error);
      }
    }

    getCameras();
  }, [useBackCamera]);

  const handleWebcamError = (error) => {
    console.log('Device Info:', {
      isMobile,
      userAgent: navigator.userAgent,
      platform: navigator.platform
    });
    
    console.log('Camera Error:', {
      name: error.name,
      message: error.message,
      stack: error.stack
    });
    
    console.log('Video Constraints:', videoConstraints);
  };

  const capture = useCallback(() => {
    const base64Image = webcamRef.current?.getScreenshot();
    if (base64Image) {
        setImgSrc(base64Image);

        // Convert base64 to Blob
        const base64ToBlob = (base64) => {
            const byteString = atob(base64.split(',')[1]);
            const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: mimeString });
        };

        const imageBlob = base64ToBlob(base64Image);

        // Pass the Blob to onImageCapture
        onImageCapture(imageBlob);
    }
}, [webcamRef, onImageCapture]);


  const retake = () => {
    setImgSrc(null);
  };

  return (
    <div className="webcam-container">
      {imgSrc ? (
        <>
          <img 
            src={imgSrc} 
            alt="captured" 
            className="captured-img"
            style={{ width: '100%', height: 'auto' }}
          />
          <button className="button" onClick={retake}>
            Retake
          </button>
        </>
      ) : (
        <>
          <div className="webcam-wrapper" style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}>
            <Webcam
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              screenshotQuality={0.8}
              videoConstraints={videoConstraints}
              onUserMediaError={handleWebcamError}
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
          <button className="button" onClick={capture}>
            Capture
          </button>
        </>
      )}
    </div>
  );
};

export default CustomWebcam;