import React from "react";
import { Link } from "react-router-dom";

const Sidebar = ({ showModeratorBoard, showAdminBoard, isCollapsed, toggleCollapse }) => {
  return (
    <div>
      <button className="toggle-btn" onClick={toggleCollapse}>
        <i className={`bi ${isCollapsed ? "bi-chevron-right" : "bi-chevron-left"}`}></i>
      </button>
      <ul className="nav flex-column">
        <li className="nav-item">
          <Link to={"/home"} className="nav-link text-light">
            <i className="bi bi-house-door"></i>
            {!isCollapsed && " Home"}
          </Link>
        </li>
        {showModeratorBoard && (
          <li className="nav-item">
            <Link to={"/mod"} className="nav-link text-light">
              <i className="bi bi-tools"></i>
              {!isCollapsed && " Moderator Board"}
            </Link>
          </li>
        )}
        {showAdminBoard && (
          <li className="nav-item">
            <Link to={"/admin"} className="nav-link text-light">
              <i className="bi bi-gear"></i>
              {!isCollapsed && " Admin Board"}
            </Link>
          </li>
        )}
        <li className="nav-item">
          <Link to={"/user"} className="nav-link text-light">
            <i className="bi bi-person"></i>
            {!isCollapsed && " User"}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
