import { Button, Group, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { IconCamera, IconUpload } from '@tabler/icons-react'; // Importing icons from Tabler Icons
import './Home.css'; // Import your custom CSS file

const Home = () => {
  const navigate = useNavigate();

  const handleCapture = () => {
    navigate('/capture');
  };

  const handleUpload = () => {
    navigate('/upload');
  };

  return (
    <div className="home-container">
      <div className="content-wrapper">
        <Text size="xl" weight={700} className="welcome-text">
          Welcome to the Image Processing App!
        </Text>
        <Group position="center" className="button-group">
          <Button
            onClick={handleCapture}
            leftIcon={<IconCamera size={18} />}
            className="submit-button"
          >
            Capture Image
          </Button>
          <Button
            onClick={handleUpload}
            leftIcon={<IconUpload size={18} />}
            className="submit-button"
          >
            Upload Image
          </Button>
        </Group>
      </div>
    </div>
  );
};

export default Home;
